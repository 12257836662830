import mapboxgl from "mapbox-gl";
import { Box, Typography } from "@mui/material";
import Boop from "./Animations/BoopAnim";
import setText from "../utils/Text";

const url = `https://api.mapbox.com/styles/v1/wxy-office/cl79fipug004215lx5vouut4w/static/-76.6123,38.9734,8.6/`;
mapboxgl.accessToken = process.env["REACT_APP_MAPBOX_ID"];

export default function StaticMap(props) {
  const w = props.w;
  const h = props.h;
  const lang = props.lang;

  return (
    <Boop rotation={2} timing={550}>
    <Box
      sx={{
        padding: ".8rem 1.25rem 1rem 1.25rem",
        "&:hover": { backgroundColor: "primary.light" },
        borderRadius: "1rem",
      }}
    >
      <Typography
        variant="h3"
        color="primary"
          sx={{
          fontWeight: 700,
          textDecoration: "underline",
        }}
      >
        {setText('view-the-b', lang)}
      </Typography>
      <Box
        component="img"
        alt="Icon of AACPS Map."
        src={url + w + "x" + h + "?access_token=" + mapboxgl.accessToken}
        sx={{ boxShadow: 6 }}
      />
      </Box>
      </Boop>
  );
}
