import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function ReadySnackbar(props) {
  const [open, setOpen] = React.useState(true);
  const [transition, setTransition] = React.useState(TransitionUp);
  const message = props.message;

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        message={message}
      />
  );
}
