import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { Box, Dialog } from '@mui/material';
import { Fab } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapSidebarInfo from './MapSidebarInfo';


function MapDialog(props) {
    const lang = props.lang
    const userSettings = props.settings
    const { onClose, open } = props;

    return (
        <Dialog onClose={onClose} open={open} sx={{ maxHeight: "90%" }}>
            <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', height: "100%" }}>
                <MapSidebarInfo lang={lang} settings={userSettings} />
            </Box>
        </Dialog>
    )
}

MapDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function MapSidebarFab(props) {
    const lang = props.lang
    const userSettings = props.settings
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Fab
            color="primary"
            aria-label="sidebar"
            onClick={handleClickOpen}
            sx={{
                position: "fixed",
                bottom: "36px",
                right: "10px",
                color: "#ffffff"
                }}>
                <InfoOutlinedIcon />
            </Fab>
            <MapDialog
                open={open}
                onClose={handleClose}
                lang={lang}
                settings={userSettings}
            />
        </div>
  );
}