import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

export default function SelectSmall(props) {
  const globalProps = props;
  const handleChange = (event) => {
    globalProps.setLang(event.target.value);
  };
  const width = window.innerWidth;

  const [color, setColor] = useState("white");

  useEffect(() => {
    {
      width < 900 ? setColor("black") : setColor("white");
    }
  }, []);

  return (
    <Box arial-label="Dropdown menu that changes page language">
      <FormControl fullWidth variant="filled">
        <Select
          variant="filled"
          labelId="Dropdown menu that changes page language"
          id="language-select"
          value={globalProps.lang}
          onChange={handleChange}
          displayEmpty
          input={<OutlinedInput />}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            borderRadius: "4px",
            height: "45px",
            color: color,
            width: "150px",
          }}
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"es"}>Español</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
