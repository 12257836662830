import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { aacpsTheme } from "./Theme.js";
import { connect } from "react-redux";
import * as actions from "./store/authActions";
import Urls from "./Urls";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

function App(props) {
  React.useEffect(() => {
    // props.setAuthenticatedIfRequired();
  }, []);

  const [ip, setIP] = useState(undefined);
  const [lang, setLang] = useState("en");
  const textDir = { en: "ltr", es: "ltr" }[lang];
  const [cookie, setCookie] = useState();
  const [address, setAddress] = useState();
  const [tabledata, setTableData] = useState({});
  const [surveyResponses, setSurveyResponses] = useState({});
  const [visibleLayer, setVisibleLayer] = useState();
  const [selectedSchool, setSelectedSchool] = useState();
  const [selectedSchoolID, setSelectedSchoolID] = useState();
  const [mapFeedback, setMapFeedback] = useState([]);
  const [mapMode, setMapMode] = useState("view");
  const [surveySubmit, setSurveySubmit] = useState(false);
  const globalProps = {
    ip: ip,
    lang: lang,
    address: address,
    cookie: cookie,
    tabledata: tabledata,
    surveyResponses: surveyResponses,
    visibleLayer: visibleLayer,
    selectedSchool: selectedSchool,
    selectedSchoolID: selectedSchoolID,
    mapFeedback: mapFeedback,
    mapMode: mapMode,
    surveySubmit: surveySubmit,
    setIP,
    setLang,
    setAddress,
    setCookie,
    setTableData,
    setSurveyResponses,
    setVisibleLayer,
    setSelectedSchool,
    setSelectedSchoolID,
    setMapFeedback,
    setMapMode,
    setSurveySubmit,
  };

  function getIP() {
    axios
      .get("https://geolocation-db.com/json/")
      .then(function (response) {
        setIP(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    getIP();
  }, []);

  useEffect(() => {
    setCookie(getCookie("_ga"));
  }, []);

  return (
    <div>
      <ThemeProvider theme={aacpsTheme}>
        <Urls store={props} globalProps={globalProps} textDir={textDir} />
      </ThemeProvider>
    </div>
  );
}

//This means that one or more of the redux states in the store are available as props
const mapStateToProps = (state) => {
  return {
    isAuthenticated:
      state.auth.token !== null && typeof state.auth.token !== "undefined",
    token: state.auth.token,
  };
};

//This means that one or more of the redux actions in the form of dispatch(action) combinations are available as props
const mapDispatchToProps = (dispatch) => {
  return {
    setAuthenticatedIfRequired: () => dispatch(actions.authCheckState()),
    logout: () => dispatch(actions.authLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
