import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import Box from "@mui/material/Box";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import LayerMenu from "./LayerMenu";
import GeocodeReadySnackbar from "./GeocodeReadySnackbar";
import MapControls from "./MapControl";
import MapCommentDialog from "./MapCommentDialog";

import mood_vbad from "../icons/MoodIcons_vbad.png";
import mood_bad from "../icons/MoodIcons_bad.png";
import mood_good from "../icons/MoodIcons_good.png";
import mood_neutral from "../icons/MoodIcons_neutral.png";
import mood_vgood from "../icons/MoodIcons_vgood.png";

import setText from "../utils/Text";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

mapboxgl.accessToken = process.env["REACT_APP_MAPBOX_ID"];

function MapController(props) {
  const globalProps = props.settings;
  const lang = globalProps.lang;
  const width = props.width;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [print, setPrint] = useState(false);
  const [lng, setLng] = useState(-76.595);
  const [lat, setLat] = useState(38.981);
  const [zoom, setZoom] = useState(9.83);
  const [geocoder, setGeocoder] = useState();
  const [geoCodeResults, setGeoCodeResults] = useState(false);
  const [coords, setCoords] = useState();
  const [hoverSchs, setHoverSchs] = useState({});
  const [ip, setIP] = useState(undefined);
  const [colors, setColors] = useState();
  const [layersLoaded, setLayersLoaded] = useState(false);
  const [mapLayers, setMapLayers] = useState();
  const [scenLayers, setScenLayers] = useState();

  const controlProps = {
    print: print,
    setPrint,
  };

  useEffect(() => {
    axios.get(`api/school_style/`).then((res) => {
      setColors(res.data);
    });
  }, []);

  useEffect(() => {
    if (width < 900) {
      setTimeout(() => {
        setGeoCodeResults(false);
      }, 3000);
    }
  }, [geoCodeResults]);

  useEffect(() => {
    if (map.current) {
      map.current.on("click", (e) => {
        if (globalProps.mapMode == "comment") {
          setCoords(e.lngLat);
        }
      });
    }
  }, [globalProps.mapMode]);

  useEffect(() => {
    if (map.current) {
      map.current.on("idle", () => {
        const layer = {
          type: "FeatureCollection",
          features: globalProps.mapFeedback,
        };
        map.current.getSource("mapFeedback").setData(layer);
      });
    }
  }, [globalProps.mapFeedback]);

  useEffect(() => {
    axios
      .get(`api/map_layers/`)
      .then((res) => {
        setMapLayers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map(
      {
        container: mapContainer.current,
        style: process.env["REACT_APP_MAP_STYLE"],
        center: [lng, lat],
        zoom: zoom,
      },
      []
    );
    map.current.on("load", () => {
      const gc = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // the placeholder is not completely interactive but what does it matter...?
        placeholder: setText("search-you", lang),
        bbox: [-77.09, 38.61, -75.88, 39.28],
        flyTo: { essential: true, speed: 3 },
      });

      map.current.addControl(gc, "top-left");

      setGeocoder(gc);

      map.current.addControl(new mapboxgl.NavigationControl());

      const moods = {
        vbad: mood_vbad,
        bad: mood_bad,
        neutral: mood_neutral,
        good: mood_good,
        vgood: mood_vgood,
      };
      Object.keys(moods).forEach((mood, ix) => {
        map.current.loadImage(moods[mood], (error, image) => {
          if (error) throw error;
          map.current.addImage(`${mood}-icon`, image, { sdf: true });
        });
      });

      // add empty layer to hold map feedback
      map.current.addSource("mapFeedback", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: globalProps.mapFeedback,
        },
      });

      map.current.addLayer({
        id: "mapFeedback",
        type: "symbol",
        source: "mapFeedback",
        layout: {
          "icon-size": 1,
          // "icon-image": "good-icon"
          "icon-image": [
            "match",
            ["get", "sentiment"],
            0,
            "vbad-icon",
            1,
            "bad-icon",
            2,
            "neutral-icon",
            3,
            "good-icon",
            4,
            "vgood-icon",
            "#000000",
          ],
        },
        paint: {
          "icon-color": [
            "match",
            ["get", "sentiment"],
            0,
            "#d32f2f",
            1,
            "#d32f2f",
            2,
            "#ff9800",
            3,
            "#2e7d32",
            4,
            "#2e7d32",
            "#000000",
          ],
        },
      });
    });

    const pointPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "school-popup",
    });

    const zonePopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "school-zone-popup",
    });

    map.current.on("mouseenter", "school points", (e) => {
      // we could make this globalProps.selectedSchool and make hover over boundaries instead
      map.current.getCanvas().style.cursor = "pointer";
      const coordinates = e.features[0].geometry.coordinates.slice();

      // if name is "Old Mill West HS" then name = "Old Mill West"

      var name = e.features[0].properties.NAME;
      if (name == "Old Mill West HS") {
        name = "Severn Run HS";
      } else if (name == "West County ES") {
        name = "Two Rivers ES";
      }

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      pointPopup.setLngLat(coordinates).setHTML(name).addTo(map.current);
    });

    map.current.on("mouseleave", "school points", () => {
      map.current.getCanvas().style.cursor = "";
      pointPopup.remove();
    });
    map.current.on("mouseleave", "Existing ES-search", () => {
      map.current.getCanvas().style.cursor = "";
      zonePopup.remove();
    });
  }, []);

  useEffect(() => {
    var geocoderInput = document.querySelector(
      ".mapboxgl-ctrl-geocoder--input"
    );

    // Add an event listener for the focus event
    if (geocoderInput === null) return;
    geocoderInput.addEventListener("focusin", () => {
      // Clear the input
      geocoderInput.value = "";
    });

    geocoderInput.addEventListener("keydown", (event) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        geocoderInput.blur();
      }
    });
  }, [geocoder]);

  // Adds Layers
  useEffect(() => {
    if (mapLayers !== undefined && colors !== undefined && map.current) {
      map.current.on("idle", () => {
        Object.keys(mapLayers).forEach(function (key) {
          var lyr = map.current.getLayer(`${mapLayers[key].name}-fill`);
          if (lyr === undefined) {
            if (mapLayers[key].name[0] === "E") {
              // Existing in color
              map.current.addLayer(
                {
                  id: `${mapLayers[key].name}-fill`,
                  source: "composite",
                  "source-layer": mapLayers[key].mapbox_id,
                  type: "fill",
                  paint: {
                    "fill-color": colors,
                    "fill-opacity": 0.7,
                  },
                  layout: {
                    visibility: "none",
                  },
                },
                "school points"
              );
            } else {
              map.current.addLayer(
                {
                  id: `${mapLayers[key].name}-fill`,
                  source: "composite",
                  "source-layer": mapLayers[key].mapbox_id,
                  type: "line",
                  paint: {
                    "line-color": "#000",
                  },
                  layout: {
                    visibility: "none",
                  },
                  // minZoom: 0,
                  // maxZoom: 16,
                },
                "school points"
              );
            }

            // to search with
            var lyr = map.current.getLayer(`${mapLayers[key].name}-search`);
            if (lyr === undefined) {
              map.current.addLayer(
                {
                  id: `${mapLayers[key].name}-search`,
                  source: "composite",
                  "source-layer": mapLayers[key].mapbox_id,
                  type: "fill",
                  paint: {
                    "fill-color": "#fff",
                    "fill-opacity": 0,
                  },
                  layout: {
                    visibility: "visible",
                  },
                },
                "school points"
              );
            }
          }
        });
        setScenLayers(mapLayers.map(({ name }) => `${name}-search`));
      });
    }
  }, [mapLayers, colors]);

  useEffect(() => {
    if (map.current) {
      map.current.on("idle", () => {
        if (map.current.getStyle().layers.length >= 79) {
          setLayersLoaded(true);
        }
      });
    }
  }, [scenLayers]);

  // click behavior for school points
  useEffect(() => {
    map.current.on("load", () => {
      map.current.on("click", "school points", (e) => {
        globalProps.setSelectedSchool(e.features[0].properties.SCH_NAME);
        globalProps.setSelectedSchoolID(e.features[0].properties.ABBREV);
      });
    });
  }, []);

  useEffect(() => {
    setIP(globalProps.ip);
  }, [globalProps.ip]);

  useEffect(() => {
    if (geocoder !== undefined && layersLoaded) {
      geocoder.on("result", function (e) {
        props.settings.setAddress(e.result);

        map.current.once("moveend", () => {
          var addressPt = e["result"]["geometry"]["coordinates"];
          var projPt = map.current.project(addressPt);

          var feat = map.current.queryRenderedFeatures(projPt, {
            layers: scenLayers,
          });
          if (width < 900) {
            setGeoCodeResults(true);
          }
          console.log(feat);
          var feat_ = feat.reduce(
            (obj, item) => ({
              ...obj,
              [item.layer.id]: {
                id: item.properties.sch_id,
                scenario: item.layer.id
                  .split("-")[0]
                  .split(" ")
                  .slice(0, -1)
                  .join(" "),
                level:
                  item.layer.id.split("-").length == 2
                    ? item.layer.id.split("-")[0].split(" ")[
                        item.layer.id.split("-")[0].split(" ").length - 1
                      ]
                    : item.layer.id.split("-")[1].split(" ")[
                        item.layer.id.split("-")[0].split(" ").length - 1
                      ],
              },
            }),
            {}
          );
          console.log(feat_);
          props.settings.setTableData(feat_);
        });

        if (ip && ip !== undefined) {
          axios
            .post("api/user_data/", {
              name: "addr_search",
              ip: ip,
              data: e,
            })
            .catch(function (error) {});
        } else {
          axios
            .post("api/user_data/", {
              name: "addr_search",
              ip: {},
              data: e,
            })
            .catch(function (error) {});
        }
      });
      geocoder.on("clear", () => {
        props.settings.setAddress();
        props.settings.setTableData({});
      });
    }
  }, [layersLoaded]);

  useEffect(() => {
    if (props.settings.address === undefined) {
      props.settings.setTableData({});
    }
  }, [props.settings.address]);

  useEffect(() => {
    if (mapLayers) {
      setPrint(true);
    }
  }, [mapLayers]);

  return (
    <Box
      sx={{ height: "calc(100vh - 48px)", overflowY: "hidden" }}
      xs={12}
      sm={12}
      className="map-container"
      ref={mapContainer}
    >
      {print ? (
        <LayerMenu
          map={map.current}
          settings={props.settings}
          mapLayers={mapLayers}
          colors={colors}
          lang={lang}
        />
      ) : null}
      {geoCodeResults ? (
        <div>
          <GeocodeReadySnackbar lang={lang} />
        </div>
      ) : null}
      <MapControls
        controlProps={controlProps}
        globalProps={globalProps}
        hoverSchs={hoverSchs}
        lang={lang}
      />
      {/* <MapCommentDialog
        mapMode={globalProps.mapMode}
        setMapMode={globalProps.setMapMode}
        point={coords}
        setPoint={setCoords}
        setMapFeedback={globalProps.setMapFeedback}
        mapFeedback={globalProps.mapFeedback}
        ip={ip}
        lang={lang}
      /> */}
    </Box>
  );
}

export default MapController;
