import text from "./text.json";

export default function setText(k, lang) {
  try {
    return text[k][lang];
  } catch (error) {
    console.log(
      `Error getting text for: ${k} and lang ${lang}.`,
      "error: " + error
    );
    return k;
  }
}
