import { Grid, Typography, Paper } from "@mui/material";
import React from "react";

export default function LevelLegend() {
  const levels = [
    { level: "ES", color: "#DA5252" },
    { level: "MS", color: "#654D7E" },
    { level: "HS", color: "#86D35F" },
  ];

  return (
    <div>
      {levels ? (
        <Paper
          sx={{
            top: "158px",
            right: "10px",
            position: "fixed",
            zIndex: 3000,
            padding: ".5rem",
            textAlign: "center",
          }}
        >
          <Grid>
            {levels.map((lev) => (
              <React.Fragment key={`${lev.level}-key`}>
                <Grid
                  item
                  sx={{
                    backgroundColor: lev.color,
                    padding: "5px",
                    display: "inline-block",
                    marginTop: "10px",
                    alignItems: "center",
                    borderColor: "#00000",
                    borderRadius: "2rem",
                    borderWidth: "2px",
                    borderStyle: "solid",
                  }}
                ></Grid>
                <Typography sx={{ lineHeight: "100%", textAlign: "center" }}>
                  {lev.level}
                </Typography>
              </React.Fragment>
            ))}
          </Grid>
        </Paper>
      ) : null}
    </div>
  );
}
