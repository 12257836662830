import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const FooterLink = ({ href, label, variant }) => {
    return (
        <Link
            to="#"
            onClick={(e) => {
                window.open(href)
                e.preventDefault();
            }}
            style={{ textDecoration: 'none' }}
        >
            <Typography variant={variant} color="primary"
                sx={{
                    "&:hover": { fontWeight: 600, textDecoration: "underline" }
                }} >
                {label}
            </Typography>
        </Link>
    );
};

export default FooterLink;