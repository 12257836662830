import * as React from "react";
import { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { styled, ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import QuestionTitle from "./QuestionTitle";
const labels = {
  1: "Low priority",
  2: "Somewhat low priority",
  3: "Neutral",
  4: "Somewhat high priority",
  5: "High priority",
};
import setText from "../../utils/Text";

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
// see https://github.com/mui/material-ui/issues/20954
// and https://codesandbox.io/s/rating-half-precision-rtl-41r6x?file=/src/App.tsx
// for guidance on overriding the ltr theming with the one-off theme below
const themeLTR = createTheme({ direction: "ltr" });

export default function QuestionRating(props) {
  const [hover, setHover] = useState(5);
  const index = props.question.id;
  const question = props.question[`question_${props.globalProps.lang}`];
  const options = props.question.options[0][`option_${props.globalProps.lang}`].options;
  const globalProps = props.globalProps;
  const [color, setColor] = useState("#faaf00"); //
  const totalStars = 12;
  const [usedStars, setUsedStars] = useState(0);
  const [value, setValue] = useState(
    // create a dictionary based on # of option questions
    // to control components state
    Object.keys(options).reduce((o, key) => ({
      ...o,
      [key]: 0,
    }))
  );

  useEffect(() => {
    setUsedStars(Object.values(value).reduce((a, b) => Number(a) + Number(b)));
    globalProps.setSurveyResponses((q) => ({ ...q, [index]: value }));
  }, [value]);

  useEffect(() => {
    if (usedStars > totalStars) {
      setColor("#ff0000");
    } else {
      setColor("#faaf00");
    }
  }, [value, usedStars]);

  function handleChange(id, val, curVal) {
    if (val == value[id]) {
      // if you click on value that's already there, remove 1
      setValue((v) => ({ ...v, [id]: val - 1 }));
    } else {
      setValue((v) => ({ ...v, [id]: val }));
    }
  }

  return (
    <div key={index}>
      <QuestionTitle
        number={props.question.question_order}
        question={question}
      />
      <FormLabel key={`formlabel-rating-key-${index}`} sx={{ color: color }}>
        {usedStars}{"/"}{totalStars} {setText("starsUsed", globalProps.lang)}
      </FormLabel>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} key={`rating-grid-key-${index}`}>
          {options.map((topic, index) => (
            <Grid
              item
              xs={12}
              key={`rating-grid-item-key-${index}`}
            >
              <Typography key={`rating-grid-item-topic-key-${index}`}>
                {topic.topic}
              </Typography>
              <ThemeProvider theme={themeLTR}>
                <Rating
                  name={`hover-feedback-${index}`}
                  key={`rating-obj-key-${index}`}
                  value={Number(value[index])}
                  precision={1}
                  onChange={(event, newValue) => {
                    handleChange(index, Number(event.target.value), value);
                  }}
                  onClick={() => { }}
                  sx={{
                    "& .MuiRating-iconFilled": {
                      color: color,
                    },
                  }}
                  emptyIcon={
                    <StarIcon
                      key={`rating-obj-star-key-${index}`}
                      style={{
                        opacity: 0.55,
                      }}
                      fontSize="inherit"
                    />
                  }
                />
              </ThemeProvider>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
