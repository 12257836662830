import React, { useState } from "react";
import axios from "axios";

import { Grid, Button, Typography } from "@mui/material";
import InputEmailField from "./InputEmailField";
import setText from "../../utils/Text";

export default function FormParent(props) {
  const lang = props.lang
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [showItems, setShowItems] = useState(true);

  function handleEmailChange(value, isValid) {
    setValue(value);
    setIsValid(isValid);
  }

  function handleSubmitForm(event) {
    axios
      .post("api/email/", {
        email: value,
      })
      .then(function (response) {
        console.log('successfully submitted email');
      })
      .catch(function (error) {
        console.log(error);
      });
    setShowItems(false);
  }

  console.log(setText('required', lang), lang)

  return (
    <div>
      <Grid container spacing={0} style={{ marginTop: "20px" }}>
        <Grid item sm={8} className="fieldLayoutMT15">
          {showItems === true ? (
            <InputEmailField
              helperText={`(${setText('required', lang)})`}
              label={setText('email', lang)}
              fieldName="Email"
              handleChange={handleEmailChange}
            />
          ) : (
            <Typography>{setText('thank-you!', lang)}</Typography>
          )}
        </Grid>
        <Grid item sm={4} align="left">
          {showItems === true ? (
            isValid === true ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitForm}
              >
                Submit
              </Button>
            ) : (
              <Button variant="contained" color="primary" disabled>
                Submit
              </Button>
            )
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
