import { Fab, Badge, Tooltip, Typography, Stack } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import ReadySnackbar from "./ReadySnackbar";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import axios from "axios";
import setText from "../utils/Text";
import LevelLegend from "./SchLevelLegend";

export default function MapControls(props) {
  const lang = props.lang;
  const [invisible, setInvisible] = useState(false);
  const controlProps = props.controlProps;
  const globalProps = props.globalProps;
  const [snackTrigger, setSnackTrigger] = useState(false);
  const hoverSchs = props.hoverSchs;
  const [fromSch, setFromSch] = useState();
  const [toSch, setToSch] = useState();

  useEffect(() => {
    if (hoverSchs["sch_id"] != undefined) {
      axios
        .get(`api/school_data/${hoverSchs["sch_id"]}/`)
        .then((r) => {
          setFromSch(r.data.name);
        })
        .catch((e) => {
          console.log("error getting school data", e);
          setFromSch(undefined);
        });
    } else {
      setFromSch(undefined);
    }

    if (hoverSchs["sch_id_new"] != undefined) {
      axios
        .get(`api/school_data/${hoverSchs["sch_id_new"]}/`)
        .then((r) => {
          setToSch(r.data.name);
        })
        .catch((e) => {
          console.log("error getting school data", e);
          setToSch(undefined);
        });
    } else {
      setToSch(undefined);
    }
  }, [props.hoverSchs]);

  function handleLayerClick() {
    controlProps.setPrint(!controlProps.print);
  }

  function handleComment() {
    globalProps.setMapMode("comment");
    setSnackTrigger(true);
    setInvisible(true);
    setTimeout(() => {
      setSnackTrigger(false);
    }, 3000);
  }

  return (
    <React.Fragment>
      <Tooltip title={setText("layerTooltip", lang)} placement="left">
        <Fab
          color="info"
          aria-label="sidebar"
          size="small"
          onClick={handleLayerClick}
          sx={{
            position: "fixed",
            bottom: "106px",
            right: "10px",
            color: "#ffffff",
          }}
        >
          <LayersOutlinedIcon />
        </Fab>
      </Tooltip>
      {/* <Tooltip title={setText("commentTooltip", lang)} placement="left">
        <Fab
          color="secondary"
          aria-label="sidebar"
          variant="extended"
          onClick={handleComment}
          sx={{
            position: "fixed",
            bottom: "160px",
            right: "10px",
            color: "#ffffff",
          }}
        >
          <Badge
            badgeContent=""
            color="error"
            sx={{ "& .MuiBadge-badge": { right: -8, top: -8 } }}
            invisible={invisible}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ pb: 0.5 }}
            >
              <MapsUgcOutlinedIcon />
              <Typography variant="h6">{setText("comment", lang)}</Typography>
            </Stack>
          </Badge>
        </Fab>
      </Tooltip> */}
      <LevelLegend />
      {snackTrigger ? (
        <ReadySnackbar message={setText("mapClickComment", lang)} />
      ) : null}
    </React.Fragment>
  );
}
