import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ScenarioButtons from "../components/ScenarioButtons";
import Switch from "@mui/material/Switch";
import axios from "axios";
import MetricButtons from "./MetricButtons";
import { Grid, Typography } from "@mui/material";
import setText from "../utils/Text";

export default function LayerMenu(props) {
  const lang = props.lang;
  const [checked, setChecked] = useState(true);
  const [mode, setMode] = useState("View Boundaries");
  const [scenarios, setScenarios] = useState([]);
  const [metrics, setMetrics] = useState();

  useEffect(() => {
    axios
      .get(`api/scenarios/`)
      .then((res) => {
        setScenarios(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    axios
      .get(`api/metrics/`)
      .then((res) => {
        if (res.data.length > 0) {
          setMetrics(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked === false) {
      setMode("View Data");
    } else {
      setMode("View Boundaries");
    }
  };

  return (
    <Box
      sx={{
        width: "261.9px",
        zIndex: 1000,
        position: "absolute",
        bottom: "42px",
        left: "10px",
      }}
    >
      
      <Grid component="label" container alignItems="center" spacing={0}>
        {metrics ? (
          <div>
            <Grid item>
              <Typography variant="body1">{metricsLabel[lang]}</Typography>
            </Grid>
            <Grid item>
              <Switch checked={checked} onChange={handleChange} />
            </Grid>
          </div>
        ) : null}
        <Grid item>
          <Typography variant="body1">{setText('view-bound', lang)}</Typography>
        </Grid>
      </Grid>

      {checked
        ? Object.keys(scenarios).map((key, index) => (
            <ScenarioButtons
              lang={lang}
              map={props.map}
              scenario={scenarios[key].scenario}
              settings={props.settings}
              mapLayers={props.mapLayers}
              index={index}
              key={`${key}-scenario-button-key`}
              colors={props.colors}
            />
          ))
        : Object.keys(metrics).map((key, index) => (
            <MetricButtons
              map={props.map}
              measure={metrics[key]}
              settings={props.settings}
              mapLayers={props.mapLayers}
              index={index}
              key={`${key}-metric-button-key`}
            />
          ))}
    </Box>
  );
}
