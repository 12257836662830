import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// import MapHeader from "../components/MapHeader";
import MainHeader from "../components/MainHeader";
import MapView from "../components/MapView";
import useWindowDimensions from "../components/UseWindowDimensions";
import MapSidebarInfo from "../components/MapSidebarInfo";
import MapSidebarFab from "../components/MapSidebarFab";
import PageHook from "../components/PageHook";
import { useNavigate } from "react-router-dom";

import history from "history/browser";

export default function Map(props) {
  const store = props.store;
  const textDir = props.textDir;
  const globalProps = props.globalProps;
  const lang = globalProps.lang;
  const isAuth = store.isAuthenticated;
  const { height, width } = useWindowDimensions();

  let navigate = useNavigate();

  useEffect(() => {
    history.replace("/map");
    let location = history.location;
  }, []);

  useEffect(() => {
    const ip = globalProps.ip;
    if (ip !== undefined) {
      PageHook(ip, "map");
    }
  }, [globalProps.ip]);

  return (
    <Box dir={textDir}>
      <MainHeader {...globalProps} />
      <Box sx={{ flexGrow: 0 }}>
        <Grid container spacing={0}>
          {width >= 900 ? (
            <Grid item xs={12} sm={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <MapSidebarInfo lang={lang} settings={globalProps} />
              </Box>
            </Grid>
          ) : (
            <MapSidebarFab lang={lang} settings={globalProps} />
          )}
          <Grid item xs={12} sm={12} md={8}>
            <MapView lang={lang} settings={globalProps} width={width} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
