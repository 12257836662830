import * as React from "react";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Button, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import FormParent from "../EmailValidation/FormParent";
import setText from "../../utils/Text";

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = 0;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export default function SignupButton(props) {
  const [anchor, setAnchor] = useState(null);
  const lang = props.lang;

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popover" : undefined;

  if (props.type == "main") {
    return (
      <HashLink
        to="/#engage"
        style={{ textDecoration: "none", color: "#000000" }}
        scroll={scrollWithOffset}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{ display: { xs: "inline", sm: "inline", md: "inline" } }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              color: "#ffffff",
            }}
          >
            {setText("sign-up-fo", lang)}
          </Typography>
        </Button>
      </HashLink>
    );
  } else {
    return (
      <div>
        <Button
          variant="contained"
          color="secondary"
          sx={{ display: { xs: "inline", sm: "inline", md: "inline" } }}
          onClick={handleClick}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              color: "#ffffff",
            }}
          >
            {setText("sign-up-fo", lang)}
          </Typography>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            <FormParent lang={lang} />
          </Typography>
        </Popover>
      </div>
    );
  }
}
