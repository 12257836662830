import { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
export default function MetricControl(props) {
  const layers = props.layers;
  // need to ensure that layers only includes the layers we want to connect w the metric
  const measure = props.measure;
  const legendData = props.legend;
  const [legend, setLegend] = useState();

  function createLegendSpans() {
    MetricControl;
    if (legendData) {
      const l = [];
      var mapStyle = legendData[0].map_style.mapStyle;
      var keys = Object.keys(mapStyle);
      keys.sort();

      for (var k in keys) {
        var text = "";

        if (k == 0) {
          text = `<= ${keys[k] * 100}%`;
        } else if (k == keys.length - 1) {
          text = `>= ${Math.round(keys[k - 1] * 100)}% `;
        } else {
          text = `${Math.round(keys[k - 1] * 100)} - ${Math.round(
            keys[k] * 100
          )}%`;
        }

        var width = 220 / keys.length; // hard-coded for now based on accordion width
        l.push(
          <Grid
            item
            key={`${k}-${mapStyle[keys[k]]}-key`}
            sx={{
              backgroundColor: mapStyle[keys[k]],
              width: `${width}px`,
              height: `${width}px`,
              padding: "5px",
              display: "inline-block",
              marginBottom: "10px",
              // display: "flex",
              alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <Typography sx={{ lineHeight: "100%", textAlign: "center" }}>
              {text}
            </Typography>
          </Grid>
        );
      }
      setLegend(l);
    }
  }

  useEffect(() => {
    createLegendSpans();
  }, [legendData]);

  function turnLayersOff() {
    for (var lay in layers) {
      props.map.setLayoutProperty(
        `${layers[lay].name}-fill`,
        "visibility",
        "none"
      );
      props.map.setLayoutProperty(
        `${layers[lay].name}-outline`,
        "visibility",
        "none"
      );
    }
  }
  function handleClick(layer) {
    // open question - do we need/want to change the selectedLayer property here?

    var visibility = props.map.getLayoutProperty(
      `${layer.name}-fill`,
      "visibility"
    );

    var fillRule = props.map.getPaintProperty(
      `${layer.name}-fill`,
      "fill-color"
    );

    // get layer fill; check and see if current layer fill == measure.matchExpression. if so, turn layers off, otherwise go to else statement
    if (visibility === "visible" && fillRule == measure.match_expression) {
      // if layer visible, turnLayersOff(), then turn button off
      turnLayersOff();

      // turn off the button
      var clickedButton = document.getElementById(
        `${measure.fname}-${layer.sch_lvl}`
      );
      clickedButton.style.backgroundColor = "white";
    } else {
      // if layer not visible, turnLayersOff(), turn layer on, and turn button on
      turnLayersOff();
      document
        .querySelectorAll(`.metric`)
        .forEach((el) => (el.style.backgroundColor = "white"));
      var clickedButton = document.getElementById(
        `${measure.fname}-${layer.sch_lvl}`
      );
      clickedButton.style.backgroundColor = "#cef5f3";
      props.map.setLayoutProperty(
        `${layer.name}-fill`,
        "visibility",
        "visible"
      );
      props.map.setPaintProperty(
        `${layer.name}-fill`,
        "fill-color",
        measure.match_expression
      );
    }
  }
  return (
    <div>
      <Grid container direction="row">
        {legend}
      </Grid>

      <br></br>
      {Object.keys(layers).map((layer, index) => (
        <Button
          key={`${layers[layer].sch_lvl}-${measure.fname}-button-key`}
          onClick={(e) => {
            handleClick(layers[layer]);
          }}
          size="small"
          variant="outlined"
          value={layer}
          // this syntax accounts for spaces
          id={`${props.measure.fname}-${layers[layer].sch_lvl}`}
          className={`${measure.fname}-button metric`}
          sx={{
            width: "36px",
            height: "34px",
            marginRight: "12px",
            backgroundColor: "white",
          }}
        >
          {layers[layer].sch_lvl}
        </Button>
      ))}
    </div>
  );
}
