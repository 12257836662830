import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
            style={{ color: '#0161AB' }}
        >
            <Typography variant="body2" color="primary"
                sx={{
                    "&:hover": { fontWeight: 600, textDecoration: "underline" }
                }}>
                {label}
            </Typography>
        </Link>
    );
};

export default ButtonMailto;