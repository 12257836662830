import { createTheme } from "@mui/material/styles";

export const aacpsTheme = createTheme({
  direction: "rtl",
  palette: {
    mode: "light",
    primary: {
      main: "#005488",
      light: "#e5f5ff",
    },
    secondary: {
      main: "#AA303C",
    },
    tertiary: {
      main: "#DDE1EA",
    },
    greys: {
      main: "#4A4A4A",
      medium: "#999999",
      light: "#F2F2F2",
      lighter: "#F9F9F9",
    },
  },
  typography: {
    fontFamily: "Zilla Slab",
    h1: {
      fontFamily: "Source Sans Pro",
      fontWeight: 900,
      fontSize: "4.5rem",
      marginBottom: "1rem",
    },
    h2: {
      fontFamily: "Source Sans Pro",
      fontWeight: 700,
      fontSize: "2rem",
      marginBottom: "1rem",
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.25rem",
      marginBottom: "0.75rem",
    },
    h4: {
      fontWeight: 700,
      fontSize: ".8rem",
      marginBottom: "0.5rem",
    },
    h5: {
      fontWeight: 700,
      fontSize: "0.5rem",
      marginBottom: "0.2rem",
    },
    h6: {
      fontFamily: "Source Sans Pro",
      fontWeight: 700,
      marginBottom: "0.2rem",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1.1rem",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { minWidth: 0 },
      },
    },
    Toolbar: {
      styleOverrides: {
        root: {
          paddingRight: 0,
          paddingLeft: 0,
        },
      },
    },
  },
});
