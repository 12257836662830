import React from "react";

export default function VideoEmbed(props) {
  const url = props.url;
  const title = props.title;
  const height = props.height;
  return (
    <iframe
      width="100%"
      height={height}
      stye={{ aspectRatio: "16/9" }}
      src={`https://www.youtube.com/embed/${url}`}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}
