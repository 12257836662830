import React, { useState, useEffect } from "react";
import { TextField, FormControl } from "@mui/material";
import isEmail from "validator/lib/isEmail";

export default function InputEmailField(props) {
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [dirty, setDirty] = useState(false);

  const handleChange = (event) => {
    const val = event.target.value;
    setValue(val);
  };

  useEffect(() => {
    if (isEmail(value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [value]);

  useEffect(() => {
    props.handleChange(value, isValid);
  }, [value, isValid]);

  return (
    <React.Fragment>
      <FormControl fullWidth={props.isFullWidth} sx={{ width: "95%" }}>
        <TextField
          error={dirty && isValid === false}
          onBlur={() => setDirty(true)}
          id={props.fieldName}
          label={props.label}
          name={props.fieldName}
          variant="outlined"
          size={"small"}
          helperText={props.helperText}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChange(e)}
        />
      </FormControl>
    </React.Fragment>
  );
}
