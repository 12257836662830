import Button from "@mui/material/Button";
import React, { useState } from "react";
import setText from "../utils/Text";

export default function ClearSchool(props) {
  const lang = props.lang
  return (
    <div>
      <Button
      variant="outlined"
        onClick={() => {
          props.settings.settings.setSelectedSchool();
        }}
      >
        {setText("remove", lang)}
      </Button>
    </div>
  );
}
