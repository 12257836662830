import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TablePaper from "./TablePaper";
import axios from "axios";
import { useState, useEffect } from "react";
import GeocoderWait from "./GeocoderWait";
import GeocoderOutside from "./GeocoderOutside";
import setText from "../utils/Text";


export function BoundaryTable(props) {
  const lang = props.lang;
  const userSettings = props.settings;
  const [schoolData, setSchoolData] = useState([]);
  const [ready, setReady] = useState(false);
  const [inDistrict, setInDistrict] = useState(true);
  const lvls = ["ES", "MS", "HS"];

  function getSchoolData(table) {
    axios.post("api/boundary_changes/", { qrf_dict: table }).then((res) => {
      if (res.data.data.status !== "outside aoi") {
        setInDistrict(true);
        setSchoolData(Object.entries(res.data.data));
      }
    });
  }

  useEffect(() => {
    if (
      userSettings.address !== undefined &&
      Object.keys(userSettings.tabledata).length > 0
    ) {
      getSchoolData(userSettings.tabledata);
      setInDistrict(true);
    } else if (
      userSettings.address !== undefined &&
      Object.keys(userSettings.tabledata).length == 0
    ) {
      setInDistrict(false);
      setReady(false);
    } else if (userSettings.address == undefined) {
      setInDistrict(true);
    } else {
      setSchoolData([]);
      setInDistrict(false);
    }
  }, [userSettings.tabledata]);

  useEffect(() => {
    if (schoolData) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [schoolData]);

  useEffect(() => {
    setSchoolData([]);
  }, [userSettings.address]);

  return (
    <Box>
      {userSettings.address && inDistrict && schoolData.length == 0 ? (
        <GeocoderWait />
      ) : null}
      {!inDistrict ? <GeocoderOutside lang={lang} open={!inDistrict} /> : null}
      {userSettings.address &&
        schoolData.length !== 0 &&
        ready &&
        inDistrict ? (
        <Box>
          <Typography
            variant="body2"
            color="primary"
            sx={{ fontWeight: 600, padding: "0 1rem 0 1rem" }}
          >
            {userSettings.address["place_name"]}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "greys.main", padding: "0 1rem 1rem 1rem" }}
          >
            {`lon ${userSettings.address["center"][0]}, lat ${userSettings.address["center"][1]}`}
          </Typography>
          <TableContainer
            sx={{ marginBottom: "1rem" }}
            key={`div-key`}
            component={TablePaper}
          >
            <Table size="small" aria-label="simple table" key={`table-key`}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="primary"
                      sx={{ fontWeight: 700 }}
                    >
                      {setText("scenario", lang)}
                    </Typography>
                  </TableCell>
                  {lvls.map((item, index) => (
                    <TableCell align="right" key={`${index}-key`}>
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ fontWeight: 700 }}
                      >
                        {lvls[index]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {schoolData.map((scen, row_index) => (
                  <TableRow
                    key={`${scen[0]}-${row_index}-key`}
                    id={`${scen[0]}-${row_index}-key`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      align="left"
                      key={`${scen[0]}-${row_index}-scenario`}
                      id={`${scen[0]}-${row_index}-scenario`}
                      sx={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        width: "18%",
                      }}
                    >
                      {scen[0]}
                    </TableCell>
                    {Object.keys(scen[1]).map((level) => (
                      <TableCell
                        align="right"
                        key={`${scen[0]}-${scen[1][level].sch_id}`}
                        id={`${scen[0]}-${scen[1][level].sch_id}`}
                        sx={{
                          fontWeight: scen[1][level].font_style,
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {scen[1][level].display_name}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
    </Box>
  );
}
