import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const message = {
  "en": 'Geocoding results are ready! Use the "i" icon to view results.',
  "es": `¡Los resultados de geocodificación están listos! Utilice el icono "i" para ver los resultados.`,
}

export default function GeocodeReadySnackbar(props) {
  const lang = props.lang
  const [open, setOpen] = React.useState(true);
  const [transition, setTransition] = React.useState(TransitionUp);

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
  };

  const handleClose = () => {
    // setOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        message={message[lang]}
        key={transition ? transition.name : ""}
      />
    </div>
  );
}
