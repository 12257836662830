import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useState, useEffect } from "react";
import ClearSchool from "./ClearSelectedSchoolButton";
import TablePaper from "./TablePaper";
import GeocoderWait from "./GeocoderWait";

export function ScenarioMetricsTable(props) {
  const lang = props.lang;
  const userSettings = props.settings;
  const [schoolData, setSchoolData] = useState();
  const na = "No school found";
  const scen_order = [
    // "Scenario 1",
    // "Scenario 2",
    "Superintendent's Recommendation",
  ];
  useEffect(() => {
    setSchoolData("waiting");
    if (userSettings.selectedSchool) {
      axios
        .get(
          `api/school_scen_metrics/${lang}/${userSettings.selectedSchoolID}/`
        )
        .then((res) => {
          setSchoolData(res.data);
          console.log(res.data);
        })
        .catch((error) => {
          setSchoolData(na);
        });
    } else {
      setSchoolData();
    }
  }, [userSettings.selectedSchool, userSettings.selectedSchoolID, lang]);

  return (
    <Box>
      {schoolData === "waiting" ? <GeocoderWait /> : null}
      {schoolData && schoolData !== na && schoolData !== "waiting" ? (
        <TableContainer component={TablePaper}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="primary">
                    {{ en: "Metric", es: "Estadística" }[lang]}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography variant="body2" color="primary">
                    {{ en: "Scenario 1", es: "Escenario 1" }[lang]}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="primary">
                    {{ en: "Scenario 2", es: "Escenario 2" }[lang]}
                  </Typography>
                </TableCell> */}
                <TableCell>
                  <Typography variant="body2" color="primary">
                    {
                      {
                        en: "Option 1 - Approved",
                        es: "Opcion 1 - Aprobado",
                      }[lang]
                    }
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(schoolData).map((row, ind) => (
                <TableRow key={`${ind}-row`}>
                  <TableCell width="50%">{row}</TableCell>
                  {scen_order.map((scen, scen_ind) => (
                    <TableCell width="16%" key={`${scen_ind}-${ind}-datapoint`}>
                      {schoolData[row][scen]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Box>
  );
}
