import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import StaticMap from "../StaticMap";
import setText from "../../utils/Text";

export default function What(props) {
    const lang = props.lang
    const textSizes = props.textSizes

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h2" sx={{ fontSize: textSizes['title2'] }}>
                    {setText('what-is-th', lang)}
                </Typography>
                <Typography
                variant="body1"
                sx={{
                    color: "greys.main",
                    fontSize: textSizes['size2'],
                    fontWeight: "500",
                    paddingBottom: "1rem",
                }}
                >
                {setText("aacpss-re", lang)}
                </Typography>
                <Typography
                variant="body1"
                sx={{ color: "greys.main", fontSize: textSizes['size2'], fontWeight: "500" }}
                >
                {setText('the-primar', lang)}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                }}
                >
                <div>
                    <Link
                    to="/map"
                    id="static-map"
                    style={{
                        textDecoration: "none",
                        color: "greys.main",
                    }}
                    >
                    <StaticMap w="450" h="425" lang={lang} />
                    </Link>
                </div>
                </Box>
            </Grid>
          </Grid>
    )
}