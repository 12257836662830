import * as React from "react";
import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { styled } from "@mui/material/styles";

const iconSize = "42px";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
    fontSize: iconSize,
    width: iconSize,
    height: iconSize,
  },
}));

const customIcons = {
  5: {
    icon: (
      <SentimentVeryDissatisfiedIcon
        sx={{ fontSize: iconSize }}
        color="error"
      />
    ),
    label: "Very Dissatisfied",
  },
  4: {
    icon: (
      <SentimentDissatisfiedIcon sx={{ fontSize: iconSize }} color="error" />
    ),
    label: "Dissatisfied",
  },
  3: {
    icon: (
      <SentimentSatisfiedIcon sx={{ fontSize: iconSize }} color="warning" />
    ),
    label: "Neutral",
  },
  2: {
    icon: (
      <SentimentSatisfiedAltIcon sx={{ fontSize: iconSize }} color="success" />
    ),
    label: "Satisfied",
  },
  1: {
    icon: (
      <SentimentVerySatisfiedIcon sx={{ fontSize: iconSize }} color="success" />
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return (
    <span
      {...other}
      style={{
        fontSize: iconSize,
        width: iconSize,
        height: iconSize,
      }}
    >
      {customIcons[value].icon}
    </span>
  );
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function RadioGroupRating(props) {
  const responseData = props.responseData;
  const setResponseData = props.setResponseData;
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    if (value !== undefined) {
      setResponseData({ ...responseData, sentiment: value });
    }
  }, [value]);

  function handleChange(val) {
    setValue(5 - val);
  }

  return (
    <StyledRating
      name="highlight-selected-only"
      defaultValue={null}
      IconContainerComponent={IconContainer}
      getLabelText={(value) => customIcons[value].label}
      highlightSelectedOnly
      dir="rtl"
      sx={{ p: "1rem 0 1rem 0" }}
      onChange={(event, newValue) => {
        handleChange(Number(event.target.value));
      }}
    />
  );
}
