import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScenarioControl from "./ScenarioControl";
import { useEffect, useState } from "react";
import setText from "../utils/Text";

export default function ScenarioButtons(props) {
  const scenario = props.scenario;
  const mapLayers = props.mapLayers;
  const [open, setOpen] = useState(false);
  const scenLayers = [];
  const [loaded, setLoaded] = useState(true);
  const layers = [];
  const lang = props.lang;

  Object.keys(mapLayers).map(function (key) {
    if (mapLayers[key].scenario === scenario) {
      scenLayers.push(mapLayers[key]);
    }
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setOpen((val) => !val);
  };

  useEffect(() => {
    if (scenario == "Existing") {
      // only set existing to open by default
      setOpen(true);
    } else {
    }
  }, []);

  return (
    <div>
      <Accordion
        sx={{
          margin: ".25rem 0 .25rem 0",
          "&.Mui-expanded": { margin: ".25rem 0 .25rem 0" },
          "&.Mui-expanded:last-of-type": { margin: ".25rem 0 .25rem 0" },
        }}
        id={`${props.index}-layer-accordion`}
        expanded={open}
        onChange={handleChange(`${props.index}-layer-accordion`)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            {setText(scenario.replace(/\s+/g, '-').toLowerCase(), lang)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ScenarioControl
            lang={lang}
            map={props.map}
            scenLayers={scenLayers}
            mapLayers={mapLayers}
            scenario={scenario}
            settings={props.settings}
            colors={props.colors}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
