import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import ComeBackSnack from "./ComeBackSnack";
import GreyDivider from "./GreyDivider";
import { BoundaryTable } from "./BoundaryTable";
import { MetricsTable } from "./SchoolMetricsTable";
import { ScenarioMetricsTable } from "./ScenarioMetricsTable";
import setText from "../utils/Text";

export default function MapSidebarInfo(props) {
  const lang = props.lang;
  const userSettings = props.settings;
  const p = "0 1em 0 1em"

  return (
    <Box
      sx={{
        overflow: "auto",
        overflowY: "auto",
        maxHeight: "calc(100vh - 96px)",
        padding: "1em 0 1em 0",
      }}
    >
      <Typography variant="h6" p={p}>{setText('find-your', lang)}</Typography>
      <Typography color="greys.main"  variant="body1" p={p} mb="1em">{setText('you-can-vi', lang)}</Typography>
      <BoundaryTable lang={lang} settings={userSettings} />
      <GreyDivider />
      <Typography variant="h6" p={p}>{setText('get-school', lang)}</Typography>
      <Typography color="greys.main" p={p} mb="1em">{setText('click-on-a', lang)}</Typography>
      <MetricsTable lang={lang} settings={userSettings} />
      <ScenarioMetricsTable lang={lang} settings={userSettings} />
      {userSettings.selectedSchool ? (
        <>
      <Typography variant="body2" p={p} mt="1em" color="greys.main" sx={{ fontWeight: 700 }}>{setText('source:-sy', lang)}</Typography>
      <Typography variant="body2" p={p} color="greys.main">{setText('future-enr2', lang)}</Typography>
      </>
      ): null}
    </Box>
  );
}
