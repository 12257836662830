import { Typography, Grid } from "@mui/material";

import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import setText from "../../utils/Text";

const keyTerms = [
  'assignment',
  'capacity',
  'charter-sc',
  'english-la',
  'enrollment',
  'feeder-pat',
  'magnet-pro',
  'magnet-sch',
  'neighborho',
  'school-bou',
  'special-ed',
  'title-i-sc',
  'utilizatio',
  'walk-zone'
];

const keyTermDescriptions = [
  'how-infreq',
  'the-total',
  'public-cha',
  'students-w',
  'the-number',
  'the-sequen',
  'certain-bo',
  'a-public-e',
  'the-public',
  'a-geograph',
  'specially',
  'public-ele',
  'expressed',
  'the-area-a'
];

export default function Terms(props) {
  const lang = props.lang;
  const textSizes = props.textSizes;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h2" sx={{ fontSize: textSizes["title2"] }}>
          {setText('key-terms', lang)}
        </Typography>
        <Grid container spacing={2}>
          {keyTerms.map((row, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={4}
              sx={{ padding: "12px 12px 12px 0" }}
            >
              <Accordion sx={{ boxShadow: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    borderRadius: "1rem",
                    padding: "1rem",
                    "&:hover": { backgroundColor: "primary.light" },
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "greys.main",
                      fontWeight: "500",
                      fontSize: textSizes["size2"],
                    }}
                  >
                    {setText(row, lang)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "greys.main",
                      fontSize: textSizes["size3"],
                    }}
                  >
                    {setText(keyTermDescriptions[index], lang)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
