import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./views/home";
import Map from "./views/map";
import Login from "./components/Login";

function Urls(props) {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login {...props.store} />} />
          <Route
            exact
            path="/"
            isAuthenticated={props.isAuthenticated}
            element={<Home {...props} />}
          />
          <Route
            path="/home"
            isAuthenticated={props.isAuthenticated}
            element={<Home {...props} />}
          />
          <Route
            path="/map"
            isAuthenticated={props.isAuthenticated}
            element={<Map {...props} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Urls;
