import axios from "axios"

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export default function PageHook(ip, page) {
        if (ip && ip !== undefined) {
    axios
    .post("api/user_data/", {
        name: "page_view",
        ip: ip,
        data: {'page': page},
    })
    .catch(function (error) {
        console.log(error);
    });
} else {
    axios
    .post("api/user_data/", {
        name: "page_view",
        ip: {},
        data: {'page': page},
    })
    .catch(function (error) {
        console.log(error);
    });
}
}