import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ClearSchool from "./ClearSelectedSchoolButton";
import TablePaper from "./TablePaper";
import GeocoderWait from "./GeocoderWait";
import setText from "../utils/Text";

export function MetricsTable(props) {
  const lang = props.lang;
  const textDir = { en: "ltr", es: "ltr" }[props.lang];
  const na = "No school found";
  const labelAlign = {
    en: "left",
    es: "left",
  }[props.lang];
  const dataAlign = {
    en: "right",
    es: "right",
  }[props.lang];
  const userSettings = props.settings;
  const [schoolData, setSchoolData] = useState();

  useEffect(() => {
    setSchoolData("waiting");
    if (userSettings.selectedSchool) {
      axios
        .get(`api/school_metrics/${lang}/${userSettings.selectedSchoolID}/`)
        .then((res) => {
          setSchoolData(res.data);
        })
        .catch((error) => {
          setSchoolData(na);
        });
    } else {
      setSchoolData();
    }
  }, [userSettings.selectedSchool, lang]);

  function replaceSchoolName(schoolName) {
    if (schoolName == "Old Mill West High") {
      schoolName = "Severn Run High";
    } else if (schoolName == "West County Elementary") {
      schoolName = "Two Rivers Elementary";
    }
    return schoolName;
  }

  return (
    <Box sx={{ paddingBottom: "1rem" }}>
      {schoolData === "waiting" ? <GeocoderWait /> : null}
      {schoolData === na ? (
        <Typography variant="body1" color="primary" sx={{ fontWeight: 700 }}>
          {`${replaceSchoolName(
            userSettings.selectedSchool
          )} is not impacted by the boundary study.`}
        </Typography>
      ) : null}
      {schoolData && schoolData !== na && schoolData !== "waiting" ? (
        <TableContainer component={TablePaper} dir={textDir}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{ fontWeight: 700 }}
                  >
                    {replaceSchoolName(userSettings.selectedSchool)}
                  </Typography>
                </TableCell>
                <TableCell align={dataAlign}>
                  <ClearSchool lang={lang} settings={props} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(schoolData).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align={labelAlign} component="th" scope="row">
                    {row}
                  </TableCell>
                  <TableCell align={dataAlign}>{schoolData[row]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Box>
  );
}
