import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import setText from "../../utils/Text";
import { useState } from "react";
import VideoEmbed from "../../utils/VideoEmbed";


export default function HowToWebsite(props) {
    const [open, setOpen] = useState(false);
    const lang = props.lang;

  const handleChange = (panel) => (event, isExpanded) => {
    setOpen((val) => !val);
  };

    return (
        <Accordion
        elevation={0}
        id={`${props.index}-layer-accordion`}
        expanded={open}
        onChange={handleChange(`${props.index}-layer-accordion`)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{p: 0}}>
          <Typography variant="h6" color="primary.main" sx={{ fontWeight: 700, "&:hover": { opacity: "80%", transition: "0.2s" }, transition: "0.2s" }}>
            {setText('howToUse', lang)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{padding: "0px"}}>
            <VideoEmbed title={setText('toolTitle', lang)} url="UWJjQOrkCXI" height="315" />
        </AccordionDetails>
      </Accordion>
    );
}