import * as React from "react";
import {
  // Container,
  Typography,
  Toolbar,
  Box,
  AppBar,
  MenuItem,
  Menu,
} from "@mui/material";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Grid } from "@mui/material";
import SignupButton from "../components/HeaderComps/SignupButton";
import MUISurvey from "./Survey/Survey";
import ChangeLanguageMenu from "../components/HeaderComps/ChangeLanguageMenu";
import MoreIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import setText from "../utils/Text";
import history from "history/browser";

export default function MainHeader(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const width = window.innerWidth;
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Grid
      container
      spacing={2}
      sx={{
        alignContent: "right",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Grid item>
        <SignupButton {...props} type={"main"} />
      </Grid>
      {/* <Grid item>
        <MUISurvey {...props} />
      </Grid> */}
      <Grid item>
        <ChangeLanguageMenu {...props} />
      </Grid>
    </Grid>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      disableRestoreFocus
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <SignupButton {...props} type={"main"} />
      </MenuItem>
      {/* <MenuItem>
        <MUISurvey lang={props.lang} {...props} />
      </MenuItem> */}
      <MenuItem>
        <ChangeLanguageMenu {...props} />
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="primary" sx={{ boxShadow: 0 }} position="static">
        <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
          <Box display="flex" sx={{ alignItems: "center" }}>
            <Link
              to="/home"
              style={{
                textDecoration: "none",
                color: "#ffffff",
                alignItems: "baseline",
                width: "100%",
              }}
              onClick={() => {
                history.replace("/home");
              }}
            >
              <Typography
                variant="h6"
                color="inherit"
                component="div"
                sx={{ lineHeight: "1.2rem" }}
              >
                {setText("aacps-redi", props.lang)}
              </Typography>
            </Link>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "inline" } }}>
            {width < 900 ? (
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            ) : null}

            {width < 900 ? renderMobileMenu : renderMenu}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
