import React from "react";
import { animated, useSpring } from 'react-spring';

const Boop = ({ rotation = 0, timing = 150, children }) => {
  const [isBooped, setIsBooped] = React.useState(false);
  const style = useSpring({
    display: 'inline-block',
    backfaceVisibility: 'hidden',
    transform: isBooped
      ? `rotate(${rotation}deg)`
      : `rotate(0deg)`,
  });

  React.useEffect(() => {
    if (!isBooped) {
      return;
    }
  }, [isBooped, timing]);

  const trigger = () => {
    setIsBooped(!isBooped);
  };

  return (
    <animated.span onMouseEnter={trigger} onMouseLeave={trigger} style={style}>
      {children}
    </animated.span>
  );
};

export default Boop