import { Button } from "@mui/material";

export default function ScenarioControl(props) {
  const mapIds = props.mapLayers.map((ml) => {
    return ml.name;
  });

  function setButtonOn(layerName) {
    var clickedButton = document.getElementById(
      `${layerName.replace(/ /g, "-")}`
    );
    clickedButton.style.backgroundColor = "#cef5f3";
  }

  function setButtonOff(layerName) {
    var clickedButton = document.getElementById(
      `${layerName.replace(/ /g, "-")}`
    );
    clickedButton.style.backgroundColor = "white";
  }

  function changeLayerVisibility(layerName, newVis, isScenLyr) {
    props.map.setLayoutProperty(`${layerName}-fill`, "visibility", newVis);
    console.log(layerName, props.map.getStyle().layers);
    // uncomment when the change layers have been added in MapView.js
    // if (isScenLyr) {
    //   props.map.setLayoutProperty(`${layerName} change`, "visibility", newVis);
    // }
  }

  function handleClick(layer) {
    var visibility = props.map.getLayoutProperty(
      `${layer.name}-fill`,
      "visibility"
    );

    // props.map.zoomToLayer(layer.name);

    var clickedIsScenarioLayer = !layer.name.includes("Existing");

    if (visibility == "visible") {
      // if visible, turn the layer off
      changeLayerVisibility(layer.name, "none", clickedIsScenarioLayer);
      setButtonOff(layer.name);
    } else {
      // otherwise, turn the layer on
      changeLayerVisibility(layer.name, "visible", clickedIsScenarioLayer);
      props.settings.setVisibleLayer(layer);
      setButtonOn(layer.name);
      // and all layers of the same type off
      for (var ml in mapIds) {
        var mapLayer = mapIds[ml];
        if (mapLayer != layer.name) {
          var isScenLyr = !mapLayer.includes("Existing");
          if (!(isScenLyr ^ clickedIsScenarioLayer)) {
            changeLayerVisibility(mapLayer, "none", isScenLyr);
            setButtonOff(mapLayer);
          }
        }
      }
    }
  }

  return (
    <div>
      {Object.keys(props.scenLayers).map((layer, index) => (
        <Button
          onClick={(e) => {
            handleClick(props.scenLayers[layer]);
          }}
          size="small"
          variant="outlined"
          value={props.scenLayers[layer].id}
          // this syntax accounts for spaces
          id={`${props.scenLayers[layer].name.replace(/ /g, "-")}`}
          className={`scenario-layer-button`}
          sx={{
            width: "36px",
            height: "34px",
            marginRight: "12px",
            backgroundColor: "white",
          }}
          key={`${index}-button-key`}
        >
          {props.scenLayers[layer].sch_lvl}
        </Button>
      ))}
    </div>
  );
}
