import { Box, Typography, Container, Grid, Divider } from "@mui/material";

import * as React from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import PageHook from "../components/PageHook";
import { useNavigate } from "react-router-dom";

import What from "../components/HomePage/What";
// import Engage from "../components/HomePage/Engage";
import Questions from "../components/HomePage/Questions";
import Terms from "../components/HomePage/Terms";
import setText from "../utils/Text";
import HowToWebsite from "../components/HomePage/HowToWebsite";

export default function Home(props) {
  const store = props.store;
  const globalProps = props.globalProps;
  const lang = globalProps.lang;
  const textDir = props.textDir;
  const textSizes = {
    title1: { xs: "2.5rem", sm: "3rem", md: "4rem" },
    title2: { xs: "1.25rem", sm: "2rem", md: "2.5rem" },
    title3: { xs: "1.1rem", sm: "1.75rem", md: "2rem" },
    size1: { xs: "1.1rem", sm: "1.2rem", md: "1.5rem" },
    size2: { xs: "1rem", sm: "1.1rem", md: "1.3rem" },
    size3: { xs: ".9rem", sm: "1rem", md: "1.1rem" },
  };
  const dividers = {
    xs: "1.5rem 0 0.5rem 0",
    sm: "3rem 0 1rem 0",
    md: "6rem 0 2rem 0",
  };

  useEffect(() => {
    globalProps.setSelectedSchool();
    globalProps.setSelectedSchoolID();
    globalProps.setAddress();
  }, []);

  useEffect(() => {
    const ip = globalProps.ip;
    if (ip !== undefined) {
      PageHook(ip, "home");
    }
  }, [globalProps.ip]);

  let navigate = useNavigate();

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [targetRef.current]);

  return (
    <Box dir={textDir}>
      <MainHeader {...globalProps} />
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          sx={{
            padding: {
              xs: "1rem 0 1.5rem 0",
              sm: "2rem 0 3rem 0",
              md: "4rem 0 6rem 0",
            },
          }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Box
              component="img"
              sx={{
                paddingBottom: "1rem",
              }}
              alt="AACPS Logo."
              src="https://www.aacps.org/cms/lib/MD02215556/Centricity/Template/GlobalAssets/images///logos/aa-logo.png"
            />
            <Typography variant="h1" sx={{ fontSize: textSizes["title1"] }}>
              {setText("school-yea", lang)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "greys.main",
                fontSize: textSizes["size1"],
                fontWeight: "600",
                paddingBottom: "1rem",
              }}
            >
              {setText("welcome-to", lang)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "greys.main",
                fontSize: textSizes["size2"],
                fontWeight: "500",
              }}
            >
              {setText("explore-th", lang)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <HowToWebsite lang={lang} />
          </Grid>
        </Grid>
        <What lang={lang} textSizes={textSizes} />
        <Divider sx={{ margin: dividers }} />
        {/* <Engage lang={lang} textSizes={textSizes} globalProps={globalProps} /><Divider sx={{ margin: dividers }} /> */}
        <Questions lang={lang} textSizes={textSizes} />
        <Divider sx={{ margin: dividers }} />
        <Terms lang={lang} textSizes={textSizes} />
      </Container>
      <Footer lang={lang} />
    </Box>
  );
}
