import { Container, Typography, Toolbar, Box, Grid } from "@mui/material";
import ButtonMailto from "./ButtonMailto";
import FooterLink from "./FooterLink";
import setText from "../utils/Text";

export default function Footer(props) {
  const lang = props.lang;
  const email = setText('email', lang);

  return (
    <Box
      sx={{
        marginTop: "8rem",
        padding: "2rem 0 8rem 0",
        flexGrow: 1,
        backgroundColor: "primary.light",
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                alt="AACPS Logo."
                src="https://www.aacps.org/cms/lib/MD02215556/Centricity/Template/GlobalAssets/images///logos/aa-logo.png"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography color="primary" variant="h6">
                {setText('contact-us', lang)}
              </Typography>
              <Typography variant="body2" color="primary">
                {setText('if-you-hav', lang)}
              </Typography>
              <ButtonMailto label={email} mailto={`mailto:${email}`} />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography color="primary" variant="h6">
                {setText('project-pa', lang)}
              </Typography>
              <FooterLink
                href="https://www.wxystudio.com/"
                variant="body1"
                label="WXY Studio"
              />
              <FooterLink
                href="https://www.publicengagementassociates.com/"
                variant="body1"
                label="Public Engagement Associates"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography color="primary" variant="h6">
                {setText('useful-lin', lang)}
              </Typography>
              <FooterLink
                href="https://www.aacps.org/"
                variant="body2"
                label={setText('aacps-offi', lang)}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body2" color="primary">
                {setText('©-2022-ann', lang)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </Box>
  );
}
