import Typography from "@mui/material/Typography";
import LayerButton from "../components/LayerButton";
import FillOutlineSwitch from "./FillOutlineSwitch";
import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MetricControl from "./MetricControl";
import axios from "axios";

export default function MetricButtons(props) {
  const measure = props.measure;
  const [legendData, setLegendData] = useState();

  useEffect(() => {
    axios.get("api/legends/").then((res) => {
      setLegendData(res.data);
    });
  }, []);

  return (
    <div>
      <Accordion
        sx={{
          margin: ".25rem 0 .25rem 0",
          "&.Mui-expanded": { margin: ".25rem 0 .25rem 0" },
          "&.Mui-expanded:last-of-type": { margin: ".25rem 0 .25rem 0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            {props.measure.label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MetricControl
            map={props.map}
            measure={props.measure}
            settings={props.settings}
            layers={props.mapLayers}
            legend={legendData}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
