import { Typography, Grid } from "@mui/material";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonMailto from "../ButtonMailto";
import setText from "../../utils/Text";

const questions = ['is-my-scho', 'when-will', 'what-param', 'will-magne', 'will-the-n', 'how-is-fut']
const answers = ['if-your-sc', 'currently', 'the-redist', 'sch-mag', 'the-new-bo', 'future-enr2']

export default function Questions(props) {
  const lang = props.lang;
  const textSizes = props.textSizes;
  const email = setText('contactaac', lang)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h2" sx={{ fontSize: textSizes["title2"] }}>
          {setText('frequently', lang)}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "greys.main",
            fontSize: textSizes["size2"],
            fontWeight: "500",
          }}
        >
          {setText('this-list', lang)}
        </Typography>
      </Grid>
      {questions.map((row, index) => (
        <Grid
          key={index}
          item
          xs={12}
          sm={12}
          md={4}
          sx={{ padding: "12px 12px 12px 0" }}
        >
          <Accordion sx={{ boxShadow: 0 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                borderRadius: "1rem",
                padding: "1rem",
                "&:hover": { backgroundColor: "primary.light" },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "greys.main",
                  fontWeight: "500",
                  fontSize: textSizes["size2"],
                }}
              >
                {setText(row, lang)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{
                  color: "greys.main",
                  fontSize: textSizes["size3"],
                }}
              >
                {setText(answers[index], lang)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
      <Grid
        key={-1}
        item
        xs={12}
        sm={12}
        md={4}
        sx={{ padding: "12px 12px 12px 0" }}
      >
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              borderRadius: "1rem",
              padding: "1rem",
              "&:hover": { backgroundColor: "primary.light" },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "greys.main",
                fontWeight: "500",
                fontSize: textSizes["size2"],
              }}
            >
              {setText('i-have-a-q', lang)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                color: "greys.main",
                fontSize: textSizes["size3"],
              }}
            >
              {setText('you-can-co', lang)}
            </Typography>
            <ButtonMailto label={email} mailto={`mailto:${email}`} />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
